import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import _ from "underscore";

import "@fontsource/poppins";
import "../components/app.css";
import CreateEvent from "../components/create/createEvent";
import CreateContent from "../components/create/createContent";
import { EVENT_TITLE, CONTENT_TITLE } from "../tools/appText";
import BackButtonIcon from "../images/arrow.svg";
import Layout from "../components/layout";

const CreatePage = (props) => {
  const content = props?.location?.state?.content;
  const contentType = content?.type;
  const [tab, setTab] = useState(!!contentType ? contentType : "event");
  const userId = useSelector((state) => state?.auth?.userId, _.isEqual);

  return (
    <Layout>
      <Grid
        container
        direction="column"
        style={{ padding: 30, paddingTop: 40, width: "100%" }}
      >
        <Grid item style={{ width: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignContent="center"
          >
            <Grid item style={{ width: "40%" }}>
              <Grid container direction="row" justifyContent="flex-start">
                <Grid item onClick={() => navigate("/")}>
                  <img src={BackButtonIcon} style={{ width: 20 }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: "60%" }}>
              <Grid container direction="row" justifyContent="flex-start">
                <Grid
                  item
                  style={{
                    marginRight: 15,
                    color: tab !== "event" ? "grey" : "white",
                  }}
                  onClick={!!content ? null : () => setTab("event")}
                >
                  {EVENT_TITLE}
                </Grid>
                <Grid item>|</Grid>
                <Grid
                  item
                  style={{
                    marginLeft: 15,
                    color: tab !== "content" ? "grey" : "white",
                  }}
                  onClick={!!content ? null : () => setTab("content")}
                >
                  {CONTENT_TITLE}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {tab === "event" ? (
          <CreateEvent userId={userId} content={content}/>
        ) : (
          <CreateContent userId={userId} content={content} />
        )}
      </Grid>
    </Layout>
  );
};

export default CreatePage;
