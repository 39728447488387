// Uploadable document types (no preview support);
import moment from "moment";
const documentTypes = [
  "doc",
  "ppt",
  "odt",
  "xls",
  "psd",
  "xlsx",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const mimeTypeIsImage = (mimeType) =>
  mimeType?.split("/")[0] === "image";
export const mimeTypeIsVideo = (mimeType) =>
  mimeType?.split("/")[0] === "video";
export const mimeTypeIsAudio = (mimeType) =>
  mimeType?.split("/")[0] === "audio";
export const mimeTypeIsPDF = (mimeType) => mimeType?.split("/")[1] === "pdf";
export const mimeTypeIsDocument = (mimeType) =>
  documentTypes?.includes(mimeType?.split("/")[1]);
export const generateCalendarLink = (
  eventDetails,
  link
) => `https://calendar.google.com/calendar/event?action=TEMPLATE&trp=true&text=${eventDetails?.name?.replace(
  / /g,
  "+"
)}&dates=${`${moment(eventDetails?.event_date).format(
  "YYYYMMDDTHHmmss"
)}Z/${moment(eventDetails?.event_end).format("YYYYMMDDTHHmmss")}Z`}&sprop=website:${link}&sprop=name:Jyv
&details=${
  !!eventDetails?.description
    ? eventDetails?.description?.replace(/ /g, "+")
    : ""
}\n\n View+messages+share+photos+using+the+link:+${link}${
  !!eventDetails?.event_address?.address
    ? `&location=${eventDetails?.event_address?.address?.replace(/ /g, "+")}`
    : ""
}`;
