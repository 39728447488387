import { Grid, ImageList, ImageListItem, Tooltip } from "@mui/material";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import ClearIcon from "@mui/icons-material/Clear";
import DefaultUploadImage from "../../images/upload-photo.png";
import DefaultUploadVideo from "../../images/upload-video.png";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MovieIcon from "@mui/icons-material/Movie";
import FilePresent from "@mui/icons-material/FilePresent";
import classnames from "classnames";
import React, { useRef, useState, useCallback } from "react";
import CardMedia from "@mui/material/CardMedia";

import {
  mimeTypeIsAudio,
  mimeTypeIsImage,
  mimeTypeIsVideo,
  mimeTypeIsPDF,
  mimeTypeIsDocument,
} from "../../utils";

const File = ({ type, name, classes, file, isEditing, contentType, hasChangedImage }) => {
  // image files
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const playOrPause = useCallback(() => {
    if (videoRef.current.paused || videoRef.current.ended) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, []);

  const onPlay = useCallback(() => setIsPlaying(true), []);

  const onPause = useCallback(() => setIsPlaying(false), []);
  const [useImageFallback, setUseImageFallback] = useState(false);

  return isEditing ? (
    contentType === "event" ? (
      hasChangedImage ? (
        <img
          role="presentation"
          src={URL.createObjectURL(file)}
          alt={name}
          className={classes.image}
          onError={() => setUseImageFallback(true)}
          style={{ width: 300, borderRadius: 5, height: "90%" }}
        />
      ) : (
        <img
          role="presentation"
          src={file}
          className={classes.image}
          onError={() => setUseImageFallback(true)}
          style={{ width: 300, borderRadius: 5, height: "90%" }}
        />
      )
    ) : (
      <video
        muted={true}
        autoPlay
        loop
        playsInline
        ref={videoRef}
        className="video"
        style={{
          width: 300,
          borderRadius: 5,
          objectFit: "cover",
          height: "90%",
        }}
      >
        <source src={file} type="video/mp4" />
      </video>
    )
  ) : mimeTypeIsImage(type) ? (
    useImageFallback ? (
      <BrokenImageIcon
        className={classes.uploadContainerIcon}
        style={{ height: 120, width: 120 }}
      />
    ) : (
      <img
        role="presentation"
        src={URL.createObjectURL(file)}
        alt={name}
        className={classes.image}
        onError={() => setUseImageFallback(true)}
        style={{ width: 300, borderRadius: 5, height: "90%" }}
      />
    ) // video files
  ) : (
    <video
      muted={true}
      autoPlay
      loop
      playsInline
      ref={videoRef}
      className="video"
      // src={URL.createObjectURL(file)}
      style={{ width: 300, borderRadius: 5, objectFit: "cover", height: "90%" }}
    >
      <source src={URL.createObjectURL(file)} type="video/mp4" />
    </video>
  ); // audio files
  // : mimeTypeIsAudio(type) ? (
  //   <AudiotrackIcon
  //     className={classes.uploadContainerIcon}
  //     style={{ height: 120, width: 120 }}
  //   />
  // ) : // pdf
  // mimeTypeIsPDF(type) ? (
  //   <embed
  //     type={type}
  //     src={URL.createObjectURL(file)}
  //     className={classes.image}
  //     style={{ height: 120, width: 120 }}
  //   />
  // ) : // document w/o preview support
  // mimeTypeIsDocument ? (
  //   <InsertDriveFileIcon
  //     className={classes.uploadContainerIcon}
  //     style={{ height: 120, width: 120 }}
  //   />
  // ) : (
  //   <FilePresent
  //     className={classes.uploadContainerIcon}
  //     style={{ height: 120, width: 120 }}
  //   />
  // );
};

const AttachmentItem = ({
  attachment,
  classes,
  handleRemove,
  handleSelect,
  isEditing,
  contentType,
  setHasChangedImage,
  hasChangedImage,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const onSelect = (e) => {
    e.preventDefault();
    if (mimeTypeIsImage(attachment["type"])) handleSelect(attachment);
  };
  const onRemove = (e) => {
    e.preventDefault();
    setIsRemoving(true);
    if (!!setHasChangedImage) setHasChangedImage(true);
    handleRemove(attachment);
  };

  const isRemovingStyleConfig = {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility .35s linear, opacity .2s linear",
  };
  return (
    <Grid
      item
      className={classnames(
        classes.imageContainer,
        isRemoving ? classes.remove : null
      )}
      style={
        isRemoving ? isRemovingStyleConfig : { display: "inline", margin: 10 }
      }
    >
      <File
        file={attachment}
        name={attachment["name"]}
        classes={classes}
        type={attachment["type"]}
        contentType={contentType}
        isEditing={isEditing}
        hasChangedImage={hasChangedImage}
      />

      {contentType !== "content" && (
        <ClearIcon className={classes.closeIcon} onClick={onRemove} />
      )}
    </Grid>
  );
};

export default function UploadPromptAttachments({
  attachments,
  classes,
  onChange,
  onRemove,
  onSelect,
  allowImagesOnly = true,
  isEditing,
  contentType,
  setHasChangedImage,
  hasChangedImage,
}) {
  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      style={{ width: "100%" }}
    >
      <input
        id="upload-content"
        style={{ display: "none" }}
        type="file"
        disabled={contentType === "content" && isEditing}
        onChange={
          isEditing ? (contentType === "content" ? null : onChange) : onChange
        }
        multiple
      />
      <label htmlFor="upload-content" style={{ cursor: "pointer" }}>
        {attachments.length === 0 ? (
          <Grid item style={{ width: "100%" }}>
            <img
              src={allowImagesOnly ? DefaultUploadImage : DefaultUploadVideo}
              style={{ width: "100%" }}
            />
          </Grid>
        ) : (
          <div
            style={{
              width: 400,
              overflow: "auto",
              whiteSpace: "nowrap",
              height: "45vh",
              direction: "row",
            }}
          >
            {attachments.map((attachment) => (
              <AttachmentItem
                attachment={attachment}
                classes={classes}
                key={attachment.name}
                handleRemove={onRemove}
                handleSelect={onSelect}
                isEditing={isEditing}
                contentType={contentType}
                setHasChangedImage={setHasChangedImage}
                hasChangedImage={hasChangedImage}
              />
            ))}
          </div>
        )}
      </label>
    </Grid>
  );
}
