import { makeStyles } from '@material-ui/core';

// Styles for log note modals
export const useUploadStyles = makeStyles((theme) => ({
  uploadModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  uploadModalContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FAFAFA',
    padding: '20px 24px 24px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexBasis: '50%',
    width: '75%',
  },
  uploadModalHeaderText: {
    fontSize: 18,
    lineHeight: '21.48px',
  },
  submitBtnLoader: {
    display: 'flex',
    alignSelf: 'center',
  },
  imageList: {
    overflowY: 'scroll',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    maxHeight: 400,
    width: "100%",
    backgroundColor: 'grey',
    borderRadius: 10,
    boxShadow: '1px 2px 5px black',
    direction: 'row',
  },
  imageContainer: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.35s linear',
    cursor: 'pointer',
    margin: '0px 8px',
    '&:hover': {
      '& $imgMask': {
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
      '& $closeIcon': {
        visibility: 'visible',
      },
    },
    position: 'relative',
  },
  image: {
    width: '100%',
   height: 360,
    objectFit: 'cover',
    display: 'inline-block',
    boxShadow: '1px 2px 5px black',
  },
  closeIcon: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.text.primary,
    borderWidth: 1,
    borderRadius: 24,
    borderStyle: 'solid',
    maxWidth: 18,
    maxHeight: 18,
    zIndex: 100,
      color: theme.palette.text.secondary,
      borderColor: theme.palette.text.secondary,
    position: 'absolute',
    top: 5,
    right: 5,
  },
  imgMask: {
    height: 120,
    width: '100%',
    opacity: 0,
    zIndex: 99,
    boxSizing: 'border-box',
    overflow: 'visible',
    transition: 'all 0.4s ease-in-out',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  textInput: {
    flex: 1,
    overflow: 'auto',
    minHeight: 125,
    backgroundColor: '#fff',
  },
  dragDropContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  dragDropContainerFooter: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
  },
  uploadContainer: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    border: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    borderRadius: 4,
    borderStyle: 'solid',
    padding: 8,
    height: 200,
  },
  uploadContainerDashed: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    border: 1,
    borderColor: 'rgba(0,0,0,0.23)',
    borderRadius: 4,
    borderStyle: 'dashed',
    padding: 16,
    flex: 1,
  },
  uploadContainerDescriptionTextContainer: {
    marginBottom: 20,
    flexWrap: 'nowrap',
  },
  uploadContainerDescriptionText: {
    fontSize: '14px',
    color: '#9E9E9E',
    flexWrap: 'nowrap',
  },
  uploadContainerSpanText: {
    textDecoration: 'underline',
    color: '#003366',
  },
  uploadContainerIcon: {
    color: '#868686',
    marginRight: 8,
  },
}));
