import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { navigate } from "gatsby";
import * as API from "../../utils/api";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import JyvButton from "../general/jyvButton";
import JyvTextField from "../general/jyvTextField";
import LanguageIcon from "@mui/icons-material/Language";
import PublicIcon from "@mui/icons-material/Public";
import KeyIcon from "@mui/icons-material/Key";
import UploadArea from "../general/uploadArea";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useUploadStyles } from "../general/uploadStyles";
import { storage } from "../../firebase";
import Loading from "../general/loading";

export default function CreateEvent(props) {
  const [open, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [date, setDate] = useState(
    !!props.content ? props.content.event_date : new Date()
  );
  const [path, setPath] = useState("");
  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState(
    !!props.content &&
      !!props.content.file_urls &&
      props.content.file_urls.length > 0
      ? props.content.file_urls
      : []
  );
  const [eventType, setEventType] = useState(
    !!props.content ? props.content.event_type : "virtual"
  );
  const [eventAccess, setEventAccess] = useState(
    !!props.content ? props.content.access : "open"
  );
  const [description, setDescription] = useState(
    !!props.content && !!props.content.description
      ? props.content.description
      : ""
  );
  const [title, setTitle] = useState(!!props.content ? props.content.name : "");
  const [invitees, setInvitees] = useState([]);
  const [address, setAddress] = useState(
    !!props.content
      ? !!props.content.address
        ? props.content.event_address
        : props.content.event_link
      : ""
  );
  const [tags, setTags] = useState("");
  const [hasChangedImage, setHasChangedImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const useStyle = useUploadStyles();

  // handle attachment changes
  const handleAttachmentChange = async (e) => {
    let fileInput = e.target;
    let newFiles = [];

    // for (var i = 0; i < e.target.files.length; i++) {
    let file = e.target.files.item(0);
    if (file.type.includes("image")) {
      newFiles.push(file);
    }

    // }

    if (newFiles.length > 0) {
      // const existingFiles = files.map((f) => f.name);
      // const filteredFiles = newFiles.filter(
      //   (f) => !existingFiles.includes(f.name)
      // );
      // const updatedFiles = [...filteredFiles];
      const updatedFiles = [...newFiles];

      // if a user is changing the image on a previously created event then set this state to true
      if (!!props.content) {
        setHasChangedImage(true);
      }
      setFiles(updatedFiles);
    }
    // clear the file input, fixes issue where you can't upload the same file twice
    return (fileInput.value = null);
  };

  // handle removing attachments
  const handleAttachmentRemove = (attachment) => {
    let filteredFiles;
    if (!!props.content) {
      filteredFiles = files.filter((f) => f !== attachment);
    } else {
      filteredFiles = files.filter((f) => f.name !== attachment.name);
    }
    setFiles(filteredFiles);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleTagsChange = (e) => {
    setTags(e.target.value);
  };

  const checkForErrors = () => {
    let errors = [];
    if (title === "") {
      errors.push("title");
    }

    // TODO: uncomment if we want these to be mandatory
    // if (description === "") {
    //   errors.push("description");
    // }

    // if (eventType === undefined) {
    //   errors.push("event type");
    // }

    // if (eventAccess === undefined) {
    //   errors.push("access information");
    // }

    // if (files.length === 0) {
    //   errors.push("cover image");
    // }

    return errors;
  };

  const handleCreateEvent = async () => {
    setIsLoading(true);

    const errors = checkForErrors();
    if (errors.length > 0) {
      setErrors(errors);
      setSnackBarMessage(`Missing ${errors.join(", ")}`);
      setIsOpen(true);
      setIsLoading(false);
      return;
    }
    const dateToMil = date.valueOf();
    let payload = {
      name: title,
      description: description,
      type: "event",
      tags: tags,
      user_id: props.userId,
      event_date: dateToMil,
      event_type: eventType,
      access: eventAccess,
      invitees: invitees,
    };

    if (eventType === "irl") payload.event_address = address;
    if (eventType === "virtual") payload.event_link = address;
    let fileNames = [];
    files.forEach((file) => fileNames.push(file.fileName));
    payload.file_names = fileNames;
    try {
      const response = await API.createContent(payload);
      if (files.length > 0) {
        let fileUrls = [];
        await Promise.all(
          files.map(async (file) => {
            const path = `${props.userId}/${
              eventAccess === "open" ? "public" : "private"
            }/events/${response.data.data.content.id}/${file.name}`;
            const storageRef = ref(storage, path);

            // 'file' comes from the Blob or File API
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(ref(storage, path));

            fileUrls.push(url);
          })
        );
        const data = {
          user_id: props.userId,
          file_urls: fileUrls,
          content_id: response.data.data.content.id,
          type: "event",
        };
        await API.setDownloadUrl(data);
      }
      navigate(`/event-details/?id=${response.data.data.content.id}`);
    } catch (e) {
      console.log(e);
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackBarMessage(message);
      setIsOpen(true);
    }
    setIsLoading(false);
  };

  const handleUpdateEvent = async () => {
    setIsLoading(true);

    const errors = checkForErrors();
    if (errors.length > 0) {
      setErrors(errors);
      setSnackBarMessage(`Missing ${errors.join(", ")}`);
      setIsOpen(true);
      setIsLoading(false);
      return;
    }

    const dateToMil = date.valueOf();
    let data = {
      name: title,
      description: description,

      tags: tags,
      event_date: dateToMil,
      event_type: eventType,
      access: eventAccess,
      invitees: invitees,
    };

    if (eventType === "irl") data.event_address = address;
    if (eventType === "virtual") data.event_link = address;
    const payload = {
      data: data,
      user_id: props.userId,
      id: props.content.id,
      type: "event",
    };

    if (hasChangedImage) {
      let fileNames = [];

      files.forEach((file) => fileNames.push(file.fileName));
      payload.file_names = fileNames;
    }

    try {
      const response = await API.updateContent(payload);

      // only update files if user changed image of event
      if (hasChangedImage) {
        if (files.length > 0) {
          let fileUrls = [];
          await Promise.all(
            files.map(async (file) => {
              const path = `${props.userId}/${
                eventAccess === "open" ? "public" : "private"
              }/events/${response.data.data.content.id}/${file.name}`;
              const storageRef = ref(storage, path);

              // 'file' comes from the Blob or File API
              await uploadBytes(storageRef, file);
              const url = await getDownloadURL(ref(storage, path));

              fileUrls.push(url);
            })
          );
          const data = {
            user_id: props.userId,
            file_urls: fileUrls,
            content_id: props.content.id,
            type: "event",
          };
          await API.setDownloadUrl(data);
        } else {
          // TODO: delete image if a user wants to remove an image entirely
        }
      }
      navigate(`/event-details/?id=${props.content.id}`);
    } catch (e) {
      console.log(e);
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackBarMessage(message);
      setIsOpen(true);
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarMessage("");
    setIsOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Grid container direction="column" style={{ marginTop: 20 }}>
      {/* <JyvModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Grid
          container
          direction="column"
          style={{ margin: 20 }}
          alignItems="center"
        >
          <Grid item>Awesome! You're event has been created </Grid>
          {eventAccess === 'invite-only' && <Grid item>Copy the link below to invite guests</Grid>}
          <Grid item style={{ marginTop: 10 }}>
            <Grid container direction="row">
              <Grid item style={{ width: "30%" }}>
                <JyvButton
                  text="Copy Link"
                  onClick={() => {
                    navigator.clipboard.writeText(path);
                    setSnackBarMessage('Link Copied')
                    setIsOpen(true);
                  }}
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item style={{ width: "30%" }}>
                <JyvButton
                  placeholder="Go To Feed"
                  text="Go To Event Details"
                  onClick={() => navigate(path)}
                  variant="contained"
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </JyvModal> */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackBarMessage}
        action={action}
      />
      <Grid item style={{ width: "100%" }}>
        <UploadArea
          onChange={handleAttachmentChange}
          onRemove={handleAttachmentRemove}
          attachments={files}
          classes={useStyle}
          isEditing={!!props.content}
          allowImagesOnly={true}
          contentType={"event"}
          hasChangedImage={!!props.content ? hasChangedImage : null}
          setHasChangedImage={!!props.content ? setHasChangedImage : null} //only pass this function if user is editing an event
        />
      </Grid>
      <Grid item style={{ width: "100%", marginTop: 10 }}>
        <JyvTextField
          placeholder="Untitled Event"
          onChange={handleTitleChange}
          variant="filled"
          value={title}
          style={{ width: "100%", marginTop: 15 }}
          fontSize={25}
        />
      </Grid>
      <Grid item style={{ width: "100%", marginTop: 10 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "100%", backgroundColor: "#424242" }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    color: "grey",
                    backgroundColor: "#424242",
                    fontFamily: `Poppins`,
                    fontSize: 15,
                  },
                }}
              />
            )}
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <JyvTextField
          id="filled-multiline-static"
          multiline
          onChange={handleDescriptionChange}
          value={description}
          rows={4}
          placeholder={
            !!props.content && !!props.content.description
              ? props.content.description
              : "Description *optional"
          }
          variant="filled"
          style={{ width: "100%" }}
          fontSize={15}
        />
      </Grid>
      {/* TODO: uncomment when tags are implemented */}
      {/* <Grid item style={{ width: "100%", marginTop: 10 }}>
        <JyvTextField
          placeholder="Tags"
          onChange={handleTagsChange}
          variant="filled"
          value={tags}
          fontSize={15}
        />
      </Grid> */}
      <Grid item style={{ marginTop: 10 }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item style={{ width: "49%" }}>
            <JyvButton
              text="Virtual"
              icon={<LanguageIcon />}
              variant={eventType === "virtual" ? "outlined" : "contained"}
              color="primary"
              onClick={() => setEventType("virtual")}
            />
          </Grid>
          <Grid item style={{ width: "49%" }}>
            <JyvButton
              text="In person"
              icon={<PublicIcon />}
              variant={eventType === "irl" ? "outlined" : "contained"}
              color="primary"
              onClick={() => setEventType("irl")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%", marginTop: 5 }}>
        <JyvTextField
          placeholder={
            eventType === "virtual" ? "Link *optional" : "Address *optional"
          }
          onChange={handleAddressChange}
          variant="filled"
          value={address}
          style={{ width: "100%" }}
          fontSize={15}
        />
      </Grid>
      {/* TODO: uncomment when we figure out recurring events
      <Grid item>
        <Grid
          container
          direction="row"
          style={{ marginTop: 20 }}
          justifyContent="space-evenly"
        >
          <Grid item>
            <JyvButton text="Single" icon={<CalendarMonthIcon />} />
          </Grid>
          <Grid item>
            <JyvButton text="Recurring" icon={<RepeatIcon />} />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item>
        <Grid container direction="row" style={{ marginTop: 20 }}>
          <KeyIcon style={{ color: "grey" }} />
          <div style={{ marginLeft: 7, color: "grey" }}>Access</div>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          style={{ width: "100%" }}
          justifyContent="space-between"
        >
          <Grid item style={{ width: "49%" }}>
            <JyvButton
              text="Open"
              onClick={() => setEventAccess("open")}
              variant={eventAccess === "open" ? "outlined" : "contained"}
              color="primary"
            />
          </Grid>
          <Grid item style={{ width: "49%" }}>
            <JyvButton
              text="Subscriber only"
              variant={eventAccess === "subscriber-only" ? "outlined" : "contained"}
              color="primary"
              onClick={() => setEventAccess("subscriber-only")}
            />
          </Grid>
        </Grid>
        <Grid item style={{ width: "100%", marginTop: 10 }}>
        <JyvButton
              text="Invite only"
              variant={eventAccess === "invite-only" ? "outlined" : "contained"}
              color="primary"
              onClick={() => setEventAccess("invite-only")}
            />
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: 20 }}
        >
          <Grid item style={{ width: "100%" }}>
            {!isLoading ? (
              <JyvButton
                text={!!props.content ? "update" : "create"}
                variant="contained"
                color="confirm"
                onClick={
                  !!props.content ? handleUpdateEvent : handleCreateEvent
                }
              />
            ) : (
              <Grid item style={{ width: "100%", padding: 15 }}>
               <Grid
                container
                style={{ width: "100%" }}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item style={{ width: "100%" }}>
                  <Loading />
                </Grid>
                <Grid item style={{ marginTop: 10 }}>
                  This may take a few seconds
                </Grid>
              </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
