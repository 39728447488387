import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { navigate } from "gatsby";
import { storage } from "../../firebase";
import * as API from "../../utils/api";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Chip from "@mui/material/Chip";
import JyvButton from "../general/jyvButton";
import JyvTextField from "../general/jyvTextField";
import DefaultUploadImage from "../../images/DefaultUploadImage.svg";
import UploadArea from "../general/uploadArea";
import { useUploadStyles } from "../general/uploadStyles";
import Loading from "../general/loading";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateContent(props) {
  const userId = props.userId;
  const [open, setIsOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [date, setDate] = useState(
    !!props.content ? props.content.post_date : new Date()
  );
  const [file, setFile] = useState(
    !!props.content ? [props.content.file_url] : []
  );
  const [description, setDescription] = useState(
    !!props.content && !!props.content.description
      ? props.content.description
      : ""
  );
  const [title, setTitle] = useState(!!props.content ? props.content.name : "");
  const [isLoading, setIsLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const useStyle = useUploadStyles();

  const checkForErrors = () => {
    let errors = [];
    if (title === "") {
      errors.push("title");
    }

    // if(description === ''){
    //   errors.push('description');
    // }

    if (file.length === 0) {
      errors.push("video");
    }

    return errors;
  };

  const handleCreateContent = async () => {
    setIsLoading(true);

    const errors = checkForErrors();
    if (errors.length > 0) {
      setErrors(errors);
      setSnackBarMessage(`Missing ${errors.join(", ")}`);
      setIsOpen(true);
      setIsLoading(false);
      return;
    }
    const dateToMil = date.valueOf();
    const payload = {
      name: title,
      description: description,
      type: "content",
      tags: tags,
      // TODO: get user_id from rootstate
      user_id: props.userId,
      post_date: dateToMil,
    };

    let fileNames = [];
    file.forEach((file) => fileNames.push(file.fileName));
    payload.file_names = fileNames;
    try {
      const response = await API.createContent(payload);
      const contentId = response.data.data.content.id;

      const path = `${userId}/public/events/${contentId}/${file.name}`;
      const storageRef = ref(storage, path);
      // 'file' comes from the Blob or File API
      await uploadBytes(storageRef, file[0]);
      const url = await getDownloadURL(ref(storage, path));
      const data = {
        user_id: userId,
        file_url: url,
        content_id: contentId,
        type: "content", //TODO: change to what ever the mime type is
      };
      await API.setDownloadUrl(data);
      navigate("/");
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackBarMessage(message);
      setIsOpen(true);
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleUpdateContent = async () => {
    setIsLoading(true);

    const errors = checkForErrors();
    if (errors.length > 0) {
      setErrors(errors);
      setIsOpen(true);
      setIsLoading(false);
      return;
    }
    const dateToMil = date.valueOf();
    const data = {
      name: title,
      description: description,
      tags: tags,
      post_date: dateToMil,
    };
    const payload = {
      data: data,
      user_id: props.userId,
      id: props.content.id,
      type: "content",
    };

    try {
      await API.updateContent(payload);
      navigate("/");
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackBarMessage(message);
      setIsOpen(true);
      console.log(e);
    }
    setIsLoading(false);
  };

  // handle attachment changes
  const handleAttachmentChange = async (e) => {
    let fileInput = e.target;
    let newFiles = [];
    // for (var i = 0; i < e.target.files.length; i++) {
    let file = e.target.files.item(0);
    if (file.type.includes("video")) newFiles.push(file);
    // }

    if (newFiles.length > 0) {
      // const existingFiles = file.map((f) => f.name);
      // const filteredFiles = newFiles.filter(
      //   (f) => !existingFiles.includes(f.name)
      // );
      // const updatedFiles = [...filteredFiles];

      setFile(newFiles);
    }
    // clear the file input, fixes issue where you can't upload the same file twice
    return (fileInput.value = null);
  };

  // handle removing attachments
  const handleAttachmentRemove = (attachment) => {
    const filteredFiles = file.filter((f) => f.name !== attachment.name);
    setTimeout(() => {
      setFile(filteredFiles);
    }, 350);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTagChange = (e) => {
    setTags(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarMessage("");
    setIsOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Grid container direction="column" style={{ marginTop: 20 }}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackBarMessage}
        action={action}
      />
      <Grid item style={{ width: "100%" }}>
        <UploadArea
          onChange={handleAttachmentChange}
          onRemove={handleAttachmentRemove}
          attachments={file}
          classes={useStyle}
          isEditing={!!props.content}
          allowImagesOnly={false}
          contentType={"content"}
          hasChangedImage={false}
        />
      </Grid>
      <Grid item style={{ width: "100%", marginTop: 10 }}>
        <JyvTextField
          placeholder="Untitled"
          onChange={handleTitleChange}
          value={title}
          variant="filled"
          style={{ width: "100%", marginTop: 15 }}
          fontSize={25}
        />
      </Grid>
      {/* TODO: uncomment when scheduling content is implmented */}
      {/* <Grid item style={{ marginTop: 10 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "100%", backgroundColor: "#424242" }}
                inputProps={{
                  ...params.inputProps,
                  style: {
                    color: "grey",
                    backgroundColor: "#424242",
                    fontFamily: `Poppins`,
                    fontSize: 15,
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid> */}
      <Grid item style={{ marginTop: 10 }}>
        <JyvTextField
          id="filled-multiline-static"
          multiline
          rows={4}
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Description *optional"
          variant="filled"
          style={{ width: "100%", marginTop: 20 }}
          fontSize={15}
        />
      </Grid>
      {/* TODO: uncomment when tags are implemented */}
      {/* <Grid item style={{ width: "100%", marginTop: 10 }}>
        <JyvTextField
          placeholder="Tags"
          onChange={handleTagChange}
          variant="filled"
          value={tags}
          fontSize={15}
        />
      </Grid> */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ marginTop: 20 }}
      >
        <Grid item style={{ width: "100%" }}>
          {!isLoading ? (
            <JyvButton
              text={!!props.content ? "update" : "create"}
              variant="contained"
              color="confirm"
              onClick={
                !!props.content ? handleUpdateContent : handleCreateContent
              }
            />
          ) : (
            <Grid item style={{ width: "100%", padding: 15 }}>
              <Grid
                container
                style={{ width: "100%" }}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item style={{ width: "100%" }}>
                  <Loading />
                </Grid>
                <Grid item style={{ marginTop: 10 }}>
                  This may take a few seconds
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
